import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.min.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/default.css'
import '../src/assets/css/style.css'
import '../src/assets/css/custom.css'

import { Storage } from './helpers/storage'

let default_language = Storage.get('default_language')
const locale = (default_language) ? default_language.code : 'en'
const language_id = (default_language) ? default_language.id : 1

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import messages from './helpers/translations.js';
const i18n = new VueI18n({
	locale: locale, // set locale
	messages, // set locale messages
})

import axios from "axios";
axios.defaults.headers.post['language_id'] = language_id;
axios.defaults.headers.get['language_id'] = language_id;

// axios.defaults.baseURL = "http://localhost:8000/api/app";
axios.defaults.baseURL = "https://api.chashm.net/api/app";
window.axios = axios;



import { store } from './store/index'
Vue.config.productionTip = false

import VueProgressBar from 'vue-progressbar'
const options = {
	color: '#bffaf3',
	failedColor: '#874b4b',
	thickness: '5px',
	transition: {
		speed: '0.2s',
		opacity: '0.6s',
		termination: 300
	},
	autoRevert: true,
	location: 'top',
	inverse: false
  }
Vue.use(VueProgressBar, options)

import pagination from 'laravel-vue-pagination';
Vue.component('pagination', pagination)

Vue.filter('dateFilter', function (value) {
	let date = new Date(value)
	const year = date.getFullYear()
	const month = date.toLocaleString('default', { month: 'long' })

	const day = date.getDate()
	const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })

	return `${day} ${month} ${year} - ${time}`
})

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

new Vue({
	
	created(){
		const html = document.documentElement // returns the html tag
    	html.setAttribute('lang', default_language ? default_language.code : 'en')
    	html.setAttribute('dir', default_language ? default_language.direction : 'ltr')
	},
	store,
	router,
	i18n,
	render: h => h(App)
}).$mount('#app')
