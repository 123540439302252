<template>
  <div
    :class="[
      'newspark-header-main-menu',
      darkClass && 'menuItem' in darkClass ? darkClass.menuItem : '',
    ]"
  >
    <ul v-if="_nav_items">
      <li v-for="(item, index) in _nav_items" :key="index" :class="`float-${default_language.alignment}`">
        <a v-if="item.child"
          >{{ item.linkText }}
          <i v-if="item.child" class="fal fa-angle-down"></i
        ></a>
        <router-link v-else :to="`${item.link}`">{{
          item.linkText
        }}</router-link>
        <ul v-if="item.child" :class="`sub-menu ${default_language.direction}`">
          <li v-for="(lvlTwo, index) in item.submenu" :key="index">
            <router-link :to="`${lvlTwo.link}`" :class="`text-${default_language.alignment}`">
              {{ lvlTwo.linkText }}
              <i v-if="lvlTwo.child" class="fal fa-angle-down"></i
            ></router-link>
            <ul v-if="lvlTwo.child" class="sub-menu">
              <li v-for="(lvlThree, index) in lvlTwo.third_menu" :key="index">
                <router-link :to="`${lvlThree.link}`">{{
                  lvlThree.linkText
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
 	props: {
		darkClass: {
			type: Object,
		},
  	},
	
	data: () => ({
	 // when you have api then delete json file (dir:components/data/) and add your data this variable
	}),

	computed: {
		default_language(){
			return this.$store.getters.default_language
		},

		categories(){
			return this.$store.getters.categories
		},
		about(){
			return this.$store.getters.about
		},

		_nav_items() {
			let items = [
				{
					"id": 1,
					"linkText": this.$t('main.home'),
					"link": "/",
					"child":false
				},
				
			]
			
			let about = {
				"id": 2,
				"linkText": this.$t('main.about_us'),
				"child": true,
				"icon": "angle-down",
				"submenu": []
			}
			this.about.map(topic => {
				const slug = topic.title[this.default_language.code].replace(/\W+/g, '-').toLowerCase()
				about.submenu.push({
					id: parseInt(Math.random() * 100),
					link: `/about/${slug}/${topic.id}`,
					linkText: topic.title[this.default_language.code]
				})
			})

			items.push(about)

			this.categories.map(category => {
				items.push({
					id: parseInt(Math.random() * 10),
					linkText: category.title[this.default_language.code],
					link: `/category/${category.title[this.default_language.code]}?page=1`,
					child: false
				})
			})

			return items
		}
	},
};
</script>

<style scoped>
.newspark-header-main-menu ul > li.float-right {
	margin-right: initial;
	margin-left: 30px;
}
.newspark-header-main-menu.header-two ul > li > a {
  color: #ffffff;
}
.newspark-header-main-menu.header-three-nav ul > li > a {
  color: white;
}
.header-three-nav {
  margin-left: 35px;
}

.newspark-header-main-menu ul > li .sub-menu.rtl {
	left: initial;
	right: 15px;
}


</style>
