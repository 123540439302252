var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',[_c('video-pop-up'),_c('drawer',{attrs:{"sidebar":_vm.sidebar},on:{"toggleSidebar":_vm.toggleSidebar}}),_c('Header',{staticClass:"header-dark",attrs:{"image":"logo-2.png","darkClass":{ menuItem: 'newspark-dark-menu-items' }},on:{"toggleSidebar":_vm.toggleSidebar}}),_c('post-gallery',{staticClass:"pt-50",attrs:{"darkClass":{
        section: 'post_gallery_sidebar_dark',
        item: 'gallery_item_dark',
      },"slides":_vm.slides,"latest_news":_vm.latest_news}}),_c('features',{attrs:{"dark":true,"featured_news":_vm.featured_news}}),_c('trending-news',{attrs:{"dark":true,"darkClass":{
        title: 'section-title-2',
        item: 'trending-news-item-dark',
        news_section: 'trending-news-post-items-dark',
        news_item: 'gallery_item_dark',
      },"trending_news":_vm.trending_news,"most_visited_news":_vm.most_visited_news}}),_c('home-one-post-area',{attrs:{"dark":true,"category_news":_vm.category_news}}),_c('footer-dark'),_c('back-to-top')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }