<template>
  <HomeDark />
</template>

<script>
import HomeDark from "../components/HomeDark/index";
export default {
  components: { HomeDark },
};
</script>

<style>
	.meta-date.rtl {
		padding-left: 0 !important;
		padding-right: 32px;
	}

	.meta-date.rtl::before {
		left: initial;
		right: 15px;
	}

	.slick-arrow.next.rtl {
		right: initial !important;
		left: 25px;
	}

	.slick-arrow.prev.rtl {
		right: initial !important;
		left: 0;
	}
</style>
