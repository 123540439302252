<template>
	<div class="post__gallery__area">
		<div class="container">
			<div class="row">
				<div class="col-lg-8">
					<div class="post_gallery_slider">
						<slider :settings="BigCardsSettings" ref="BigCards">
							<template v-for="(slide, index) in slides">
								<big-video-card-with-des :datas="slide" :key="index" />
							</template>
						</slider>
					</div>
				
				</div>
				<div class="col-lg-4">
					<slide-side-bar
						:trendingShortPost="false"
						:signup="false"
						:trendingBigPost="false"
						:ad="false"
						:sharePost="false"
						:darkClass="darkClass"
						role="sidebar"
						:news="news"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import Slider from "../Helpers/Slider.vue";
import BigVideoCardWithDes from "../Utility/Cards/BigVideoCardWithDes.vue";
import SlideSideBar from "../Utility/Sidebar/SlideSideBar.vue";
export default {
	components: { Slider, BigVideoCardWithDes, SlideSideBar},
	props: {
		darkClass: {
			type: Object,
			default: () => {},
		},

		slides: {
			type: Array,
			required: true
		},

		latest_news: {
			type: Array,
			required: true,
		}
	},

	data: () => ({

		selectedGallery: "trendy",
		miniCardsSettings: {
			slidesToShow: 6,
			slideToScroll: 1,
			autoplay: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 3,
						slideToScroll: 1,
						autoplay: true,
					},
				},
			],
		},
		BigCardsSettings: {
			slidesToShow: 1,
			slideToScroll: 1,
			autoplay: true,
			arrows: true,
		},

		news: [],
	}),
	methods: {
		//post gallery
		postGalleryPrev() {
			this.$refs.BigCards.prev();
			this.$refs.miniCards.prev();
		},
		postGalleryNext() {
			this.$refs.BigCards.next();
			this.$refs.miniCards.next();
		},
		selectGalleryTab(value) {
			this.selectedGallery = value;
		},
	},

	mounted () {
		this.news = this.latest_news
	},
};
</script>

<style>
	.slick-slide > div {
		width: 100%;
	}
</style>
