<template>
	<div :class="[ 'post_gallery_sidebar', darkClass && 'section' in darkClass ? darkClass.section : '',]">
		<div class="tab-content">
			<div :class="['tab-pane fade show active',]" role="tabpanel" aria-labelledby="pills-home-tab">
				<div class="post_gallery_items" v-if="datas && datas.length > 0">
					<template v-for="(small, index) in datas.slice(0, 5)">
						<row-card
							:class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
							:category="true"
							:datas="small"
							:key="index"
						/>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Slider from "../../Helpers/Slider.vue";
import DivideCard from "../Cards/DivideCard.vue";
import RowCard from "../Cards/RowCard.vue";

export default {
  	components: { RowCard, DivideCard, Slider },
  	props: {
		datas: {
			type: Array,
			default: () => [],
		},
		darkClass: {
			type: Object,
			default: () => {},
		},
  	},
	

	data: () => ({
		selectedGallery: "trendy",
		trendingNews: {
			autoplay: true,
			slideToScroll: 1,
			slidesToShow: 1,
			arrows: false,
			dots: false,
		},
	}),

	methods: {
		selectGalleryTab(value) {
			this.selectedGallery = value;
		},

		trendingNewsPrev() {
			this.$refs.trendingNews.prev();
		},

		trendingNewsNext() {
			this.$refs.trendingNews.next();
		},
	},
};
</script>

<style></style>
