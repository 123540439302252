<template>
	
	<router-link
		:to="`/news/${slug}/${news.id}`"
		class="gallery_item gallery_item-style-2"
	>
		<div class="gallery_item_thumb">
				<img :src="thumb_url" alt="gallery" />
				<!-- <div class="icon"><i class="fas fa-bolt"></i></div> -->
		</div>

		<div class="gallery_item_content">
			
				<div class="post-meta">
					<div class="meta-categories" v-if="category">
						<router-link :to="`category/${category.title[default_language.code]}`">{{ category.title[default_language.code] }}</router-link>
					</div>
					<div class="meta-date">
						<span>{{ news.created_at | dateFilter }}</span>
					</div>
				</div>

				<h4 class="title">
					<router-link :to="`/news/${slug}/${news.id}`">{{ news.title }}</router-link>
				</h4>
				<span>{{ count }}</span>
		</div>
	</router-link>

  </template>
  
  <script>
export default {
	props: {
		news: {
			type: Object,
			required: true
		},

		count: {
			type: Number,
			required: false
		},
	},

	computed: {
		default_language(){
			return this.$store.getters.default_language
		},
		
		category() {
			return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
		},


		thumb_url() {
			let thumb_url = ''
			if(this.news.cover){
				let url = this.news.cover.original_url
				let re = /(?:\.([^.]+))?$/;
				let extension = re.exec(url)[1];
	
				let name = this.news.cover.name
	
				const name_index = url.indexOf(name)
				url = url.substring(0, name_index) + 'conversions/' + url.substring(name_index);
	
				const index = url.indexOf(extension)
				url = url.substring(0, index - 1) + '-thumb' + url.substring(index - 1);
	
				let new_extension = extension.replace('e', '')
	
				thumb_url = url.replace(extension, new_extension)
			}else{
				thumb_url = ''
			}

			return thumb_url
		},

		slug(){
			return this.news.title.replace(/\s+/g, '-').toLowerCase();
		}
	},
};
  </script>
  
  <style></style>
  