export default  {
	en: {
		main: {
			home: 'Home',
			about_us: 'About Us',
			contact_us: 'Contact Us',
			privacy_policy: 'Privacy Policy',
			latest_news: 'Latest News',
			featured_news: 'Featured News',
			trending_news: 'Trending News',
			follow_us: 'Follow Us',
			read_more: 'Read More',
			most_views: 'Most Views',
			copyright: 'All rights reserved',
			reporter: 'Reporter',
		}
	},

	fa: {
		main: {
			home: 'خانه',
			about_us: 'در باره ما',
			contact_us: 'تماس با ما',
			privacy_policy: 'حقوق خصوصی',
			latest_news: 'خبر تازه',
			featured_news: 'اخبار ویژه',
			trending_news: 'اخبار داغ',
			follow_us: 'ما را دنبال کنید',
			read_more: 'بیشتر بخوانید',
			most_views: 'پر بازدیدترین ها',
			copyright: 'تمام حقوق محفوظ است',
			reporter: 'گزارشگر',
		}
	},

	ps: {
		main: {
			home: 'کور',
			about_us: 'زمونږ په اړه',
			contact_us: 'زمونږ سره اړیکه',
			privacy_policy: 'خصوصی حقوق',
			latest_news: 'تازه خبر',
			featured_news: 'خاص خبرونه',
			trending_news: 'تاوده خبرونه',
			follow_us: 'مونږ تعقیب کړی',
			read_more: 'نور ولوله',
			most_views: 'ډیر لیدل شوی',
			copyright: 'ټول حقوق محفوظ دی',
			reporter: 'راپور جوړونکی',
		}
	}
}