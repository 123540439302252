<template>
  <div id="app">
	<vue-progress-bar></vue-progress-bar>
	<template v-if="loaded">
		<router-view />
	</template>
  </div>
</template>


<script>
export default {
	name: 'NewsApp',
	data() {
		return {
			loaded: false
		}
	},
	methods: {
		async init() {
			try {
				this.$Progress.start()
				await this.$store.dispatch('init')
				this.loaded = true
				this.$Progress.finish()
			} catch (error) {
				this.$Progress.fail()
			}
		}
	},
	mounted () {
		this.init()
	},
}
</script>