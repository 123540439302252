<template>
	<router-link :to="`/news/${slug}/${news.id}`" v-if="stype === 'small'" class="feature-post d-block">
		<div class="feature-post-thumb">
			<img :src="news.cover.preview_url" alt="feature" />
		</div>
		<div :class="`feature-post-content ${default_language.direction}`">
			<div class="post-meta">
				<div class="meta-categories" v-if="category">
					<router-link :to="`category/${category.title[default_language.code]}`">{{ category.title[default_language.code] }}</router-link>
				</div>
				<div :class="`meta-date ${default_language.direction}`">
					<span>{{ news.created_at | dateFilter }}</span>
				</div>
			</div>
			<h4 :class="`title text-${default_language.alignment}`">
				<router-link :to="`/news/${slug}/${news.id}`">{{ news.title }}</router-link>
			</h4>
		</div>
	</router-link>

	<router-link :to="`/news/${slug}/${news.id}`" v-else-if="stype === 'xx'" class="single-play-post-item d-block">
		<img :src="require(`@/assets/images/` + datas.image)" alt="feature" />
		<div class="single-play-post-content">
			<div class="post-meta">
				<div class="meta-categories" v-if="category">
					<router-link :to="`category/${category.title[default_language.code]}`">{{ category.title[default_language.code] }}</router-link>
				</div>
				<div class="meta-date">
					<span>{{ news.created_at | dateFilter }}</span>
				</div>
			</div>
			<h3 class="title">
				<router-link :to="`/news/${slug}/${news.id}`">{{ news.title }}</router-link>
			</h3>
		</div>
		<div v-if="datas.action === 'play'" class="play-btn">
			<a class="video-popup" @click.prevent="$store.dispatch('toggleVideo')"><i class="fas fa-play"></i></a>
		</div>
		<div v-if="datas.action === 'trending'" class="trending-btn">
			<a href="#"><i class="fas fa-bolt"></i></a>
		</div>
	</router-link>
</template>

<script>
export default {
	props: {
		datas: {
			type: Object,
		},
		stype: {
			type: String,
		},

		news: {
			type: Object,
			required: false
		}
	},

	computed: {
		default_language(){
			return this.$store.getters.default_language
		},
		
		category() {
			return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
		},

		slug(){
			return this.news.title.replace(/\s+/g, '-').toLowerCase();
		}
	},
};
</script>

<style scoped>
	.feature-post-content.rtl {
		left: initial;
		right: 15px;
	}
</style>
