<template>
	<div class="bussiness-post-item mb-40">
		<div :class="`bussiness-post-thumb ${default_language.direction}`">
			<router-link :to="`/news/${slug}/${news.id}`" class="text-white">
				<img
					:src="cover"
					alt="business"
				/>
			</router-link>
		</div>
		<div class="bussiness-post-content">
			<h3 :class="`title text-${default_language.alignment}`">
				<router-link :to="`/news/${slug}/${news.id}`" class="text-white">
					{{ news.title }}
				</router-link>
			</h3>
			<div class="meta-date-link">
				<span class="text-white">{{ news.created_at | dateFilter }}</span>
			</div>
			<p :class="`text-white text-${default_language.alignment}`">{{ news.excerpt }}</p>
			<router-link :to="`/news/${slug}/${news.id}`" :class="`text-white float-${default_language.alignment}`">
				{{ $t('main.read_more') }}
				<img src="@/assets/images/arrow-2.svg" alt=""/>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			news: {
				type: Object,
				required: true
			}
		},

		computed: {
			default_language(){
				return this.$store.getters.default_language
			},
			cover() {
				if(this.news.cover){
					return this.news.cover.preview_url
				}

				return '@/assets/images/business-post/business-post-1.jpg'
			},

			slug(){
				return this.news.title.replace(/\s+/g, '-').toLowerCase();
			}
		},
	}
</script>

<style scoped>
	.bussiness-post-item .bussiness-post-thumb.rtl {
		margin-right: initial;
		margin-left: 25px !important;
	}
</style>