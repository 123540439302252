<template>
  <div class="header-topbar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <div class="topbar-trending">
            <span :class="`float-${default_language.alignment}`">{{ $t('main.latest_news') }}</span>
            <div class="trending-slider">
              	<span
					@click="prev"
					:class="`prev slick-arrow ${default_language.direction == 'rtl' ? 'rtl' : ''}`"
					style="display: block"
				><i class="fal fa-angle-left"></i></span>

              <slider :settings="settings" ref="carousel">

                  <div  v-for="(trend, index) in trends" :key="index" class="trending-item">
                    <a :href="(trend.target)" v-if="trend.target">
						<p :class="`text-${default_language.alignment}`">
							{{ trend.description }}
						</p>
					</a>
					<p v-else :class="`text-${default_language.alignment}`">
						{{ trend.description }}
					</p>
                  </div>

              </slider>
				<span
					@click="next"
					:class="`next slick-arrow ${default_language.direction == 'rtl' ? 'rtl' : ''}`"
					:style="`display: block`"
				>
				<i class="fal fa-angle-right"></i></span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="topbar-social d-flex align-items-center">
            <p :class="default_language.direction">{{ date.toLocaleString('default', { weekday: 'long'})  }}, {{ date.toLocaleString('default', { month: 'long'})  }} {{ date.getDate() }}, {{ date.getFullYear() }}</p>
            <div :class="`social ${default_language.direction}`">
              <ul>
                <li v-for="(media, index) in social_media" :key="index">
                  <a :href="media.link" target="_blank"><i :class="media.icon"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "../../Helpers/Slider.vue";
export default {
	components: { Slider },
	data: () => ({
		settings: {
			dots: false,
			arrows: false,
			autoplay: true,
		},
	}),
	computed: {
		default_language(){
			return this.$store.getters.default_language
		},

		trends() {
			return this.$store.getters.trends 
		},

		date(){
			return new Date()
		},

		social_media(){
			return this.$store.getters.social_media
		}
	},
	methods: {
		next() {
			this.$refs.carousel.next();
		},
		prev() {
			this.$refs.carousel.prev();
		},
	},
};
</script>

<style scoped>


	.topbar-social p.rtl {
		padding-right: initial;
		padding-left: 20px;
	}

	.social.rtl ul {
		padding: 0 !important;
	}

	.social.rtl ul a {
		margin-left: 0 !important;
		margin-right: 10px;
	}
</style>