<template>
	<div>
	  <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
  
	  <Header @toggleSidebar="toggleSidebar" class="header-dark" :darkClass="{ menuItem: 'newspark-dark-menu-items' }"  />
	  <!--====== POST LAYOUT 1 PART START ======-->
	  <section class="post-layout-1-area post-layout-3-area pb-80 post__gallery__area">
		  <div class="container">
			  <div class="row">
					<div class="col-lg-12">
						<div class="about-author-content">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><router-link :to="{path: '/'}" class="text-white">{{ $t('main.home') }}</router-link></li>
									<li class="breadcrumb-item active" aria-current="page" v-if="category">
										{{ category.title[default_language.code] }}
									</li>
								</ol>
							</nav>
						</div>
					</div>
					<div class="col-lg-8">
						<div class="post-layout-top-content post-layout-top-content-3">
							
							<div class="post-content">
								<h3 :class="`title text-${default_language.alignment}`">
									{{ news.title }}
								</h3>
								<div class="post-author">
									<div class="author-info pl-0">
										<ul>
											<li>{{ news.created_at | dateFilter }}</li>
										</ul>
									</div>

									<div class="author-social" v-if="loaded">
										<ul>
											<li>
												<ShareNetwork
													network="email"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
												>
													<i class="fas fa-envelope"></i>
												</ShareNetwork>
											</li>
											
											<li>
												<ShareNetwork
													network="facebook"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
													:quote="news.excerpt"
													:hashtags="category.title[default_language.code]"
												>
													<i class="fab fa-facebook-f"></i>
												</ShareNetwork>
											</li>
											<li>
												<ShareNetwork
													network="twitter"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
													:hashtags="category.title[default_language.code]"
												>
													<i class="fab fa-twitter"></i>
												</ShareNetwork>
											</li>
											<li>
												<ShareNetwork
													network="linkedin"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
													:hashtags="category.title[default_language.code]"
												>
													<i class="fab fa-linkedin"></i>
												</ShareNetwork>
											</li>
											<li>
												<ShareNetwork
													network="reddit"
													:url="url"
													:title="news.title"
												>
													<i class="fab fa-reddit"></i>
												</ShareNetwork>
											</li>
											<li>
												<ShareNetwork
													network="WhatsApp"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
													:hashtags="category.title[default_language.code]"
												>
													<i class="fab fa-whatsapp"></i>
												</ShareNetwork>
											</li>
											
											<li>
												<ShareNetwork
													network="telegram"
													:url="url"
													:title="news.title"
													:description="news.excerpt"
												>
													<i class="fab fa-telegram"></i>
												</ShareNetwork>
											</li>
										</ul>
									</div>
								</div>
								<div class="thumb" v-if="news.cover">
									<img :src="news.cover.original_url" alt="" />
								</div>
							</div>

							<div :class="`post-text mt-30 text-${default_language.alignment}`">
								<div v-html="news.description"></div>

								
								<div class="post-author" v-if="news.reporter">
									<div class="author-info">
										<a :href="news.reporter.profile">

											<div class="thumb" v-if="news.reporter.photo">
												<img :src="news.reporter.photo.preview_url" alt="" />
											</div>
											<h5 class="title">{{ news.reporter.name }}</h5>
											<ul>
												<li>{{ news.created_at | dateFilter }}</li>
											</ul>
										</a>
									</div>
									<div class="author-social" v-if="(news.reporter && news.reporter.profile)">
										<div class="author-social">
											<ul>
												<li>
													<a :href="news.reporter.profile" target="_blank"><i class="fab fa-facebook-f"></i></a>
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>
						
							<div class="post-tags">
								<!-- <ul>
								<li>
									<a href="#"><i class="fas fa-tag"></i> Tags</a>
								</li>
								<li><a href="#">Health</a></li>
								<li><a href="#">World</a></li>
								<li><a href="#">Corona</a></li>
								</ul> -->
							</div>
						</div>

						<div class="mt-2">
							<div class="row">
								<div class="col-md-3" v-for="(img, index) in news._media" :key="index">
									<div class="card gallery-card">
										<img :src="img.preview_url" alt="" @click="gallery_index = index">
									</div>
								</div>
							</div>

							<vue-gallery-slideshow :images="images" :index="gallery_index" @close="gallery_index = null"></vue-gallery-slideshow>
						</div>
						
					</div>
				  <div class="col-lg-4">
  
					  <slide-side-bar
						  :trendingShortPost="false"
						  :signup="false"
						  :trendingBigPost="false"
						  :ad="false"
						  :sharePost="false"
						  :darkClass="{
							  section: 'post_gallery_sidebar_dark',
							  item: 'gallery_item_dark',
						  }"
						  role="sidebar"
						  :news="latest_news"
					  />
  
				  </div>
			  </div>
		  </div>
	  </section>
  
	  
  
  
  
  
	  <!--====== ADD PART ENDS ======-->
	  <footer-dark></footer-dark>
  
	  <!--====== GO TO TOP PART START ======-->
	  <back-to-top></back-to-top>
	  <!--====== GO TO TOP PART ENDS ======-->
	</div>
  </template>
  
  <script>
  import Header from "../../Utility/Header/index";
  import StyleOne from "../../Utility/Sidebar/StyleOne.vue";
  import SlideSideBar from "../../Utility/Sidebar/SlideSideBar.vue";
  import Posts from "../../Data/NewsRowCard";
  import OurLatestNews from "../../Utility/Common/OurLatestNews.vue";
  import FooterDark from "../../Utility/Footer/FooterDark.vue";
  import BackToTop from "../../Helpers/BackToTop.vue";
  import Drawer from "../../Mobile/Drawer.vue";
  import VueGallerySlideshow from 'vue-gallery-slideshow';
  export default {
	  components: { Header, StyleOne, SlideSideBar, OurLatestNews, Drawer, FooterDark, BackToTop,VueGallerySlideshow },
	  
	  computed: {
		  news_id() {
			  // return 1
			  return this.$route.params.news_id 
		  },
  
		  default_language(){
			  return this.$store.getters.default_language
		  },
  
		  category(){
			  return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
		  },

		  images(){
			return this.news._media ? this.news._media.map(m => m.original_url) : []
		  },

		  url(){
			return window.location.href
		  },

	  },
  
	  data: () => ({
		  posts: Posts.data,
		  sidebar: false,
		  news: {},
		  latest_news: [],
		  gallery_index: null,
		  loaded: false,
	  }),
  
	  created() {
		  document.addEventListener("scroll", this.topToBottom);
	  },
  
	  methods: {
		  toggleSidebar() {
			  this.sidebar = !this.sidebar;
		  },
		  topToBottom() {
			  const result = document.querySelector(".go-top");
			  if (
				  document.body.scrollTop > window.innerHeight ||
				  document.documentElement.scrollTop > window.innerHeight
			  ) {
				  result.classList.add("active");
			  } else {
				  result.classList.remove("active");
			  }
		  },
  
		  get_news(){
			  this.$Progress.start()
			  axios.get(`news/${this.news_id}`)
			  .then(({data}) => {
				  this.news = data.news
				  this.latest_news = data.latest_news
				  this.$Progress.finish()
				  this.loaded = true
			  }).catch((err) => {
				  this.$Progress.fail()
				  this.$Progress.finish()
			  });
		  }
	  },
  
	  mounted () {
		  document.body.classList.add("home-dark");
		  this.get_news()
	  },
  
	  beforeDestroy() {
		  document.body.classList.remove("home-dark");
	  },
  
	  watch: {
		  news_id: {
			  handler(val, old){
				  this.get_news()
			  }
		  }
	  }
  };
  </script>
  
<style>
	.vgs__container {
		top: 20% !important;
	}

	.gallery-card img {
		cursor: pointer;
	}
</style>
  