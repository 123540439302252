<template>
  <section class="trending-news-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
			<div :class="['section-title', darkClass && 'title' in darkClass ? darkClass.title : '',]">
				<h3 :class="`title text-${default_language.alignment}`">{{ $t('main.trending_news') }}</h3>
			</div>

			<div class="trending-news-slider position-relative">
				<span
					@click="trandingPrev"
					:class="`prev slick-arrow ${default_language.direction}`"
					style="display: block"
					><i class="fal fa-angle-left"></i
				></span>

				<slider :settings="trendingSettings" ref="trendingSlider">
					<template v-for="(data, index) in trending_news">
						<div :key="index" class="px-2">
							<trending-news-card
								:class="[
									darkClass && 'item' in darkClass ? darkClass.item : '',
								]"
								stype="col"
								:news="data"
							/>
						</div>
					</template>
				</slider>
				<span
					@click="trandingNext"
					:class="`next slick-arrow ${default_language.direction}`"
					style="display: block"
					><i class="fal fa-angle-right"></i
				></span>
			</div>
	
        </div>
		
        <div class="col-lg-4">

			<div class="trending-right-sidebar">
				<FollowCount :dark="dark" />
				<div class="trending-most-view mt-25">
					<div :class="['section-title', dark ? 'section-title-2' : '']">
						<h3 :class="`title text-${default_language.alignment}`">{{ $t('main.most_views') }}</h3>
					</div>
				</div>

				<div class="trending-sidebar-slider position-relative">
					<span
						@click="tssPrev"
						:class="`prev slick-arrow ${default_language.direction}`"
						style="display: block"
						><i class="fal fa-angle-left"></i
					></span>

					<slider :settings="trendingSidebarSlide" ref="trendingSidebarSlide">
						<div class="post_gallery_items" v-for="(chunk, index) in visited_news" :key="index">
							<template v-for="(news, i) in chunk">
								<most-visited-news-card
									:class="'gallery_item_dark'"
									:count="(i + 1) + (index * visited_news[0].length)"
									:key="`${i}_${index}`"
									:news="news"
								/>
							</template>
						</div>

					</slider>

					<span
						@click="tssNext"
						:class="`next slick-arrow ${default_language.direction}`"
						style="display: block"
						><i class="fal fa-angle-right"></i
					></span>
				</div>
			</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from "../Helpers/Slider.vue";
import DivideCard from "../Utility/Cards/DivideCard.vue";
import TrendingNewsCard from "../Utility/Cards/TrendingNewsCard.vue";
import TrendingPosts from "../Data/TrendingPost";
import smallPostGallery from "../Data/NewsRowCard";
import RowCard from "../Utility/Cards/RowCard.vue";
import FollowCount from "../Utility/FollowCount/index";
import MostVisitedNewsCard from "../Utility/Cards/MostVisitedNewsCard.vue";
export default {
  	components: { Slider, DivideCard, TrendingNewsCard, MostVisitedNewsCard, RowCard, FollowCount },
	props: {
		darkClass: {
			type: Object,
		},
		dark: {
			type: Boolean,
			default: false,
		},

		trending_news: {
			type: Array,
			required: true
		},

		most_visited_news: {
			type: Array,
			required: true
		}
	},
	computed: {
		default_language(){
			return this.$store.getters.default_language
		},

		visited_news() {
			const splitEvery = (n, xs, y=[]) => xs.length===0 ? y : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]))
			let news = this.most_visited_news
			return splitEvery(3, news)
		}
	},
	data: () => ({
		smallPostGallery: smallPostGallery.data,
		TrendingPosts: TrendingPosts.data,
		trendingSettings: {
			autoplay: true,
			arrows: false,
			slidesToShow: 2,
			slideToScroll: 1,
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slideToScroll: 1,
					},
				},
			],
		},
		trendingSidebarSlide: {
			autoplay: true,
			arrows: false,
			slidesToShow: 1,
			slideToScroll: 1,
		},
  	}),
	methods: {
		//trending
		trandingPrev() {
			this.$refs.trendingSlider.prev();
		},
		trandingNext() {
			this.$refs.trendingSlider.next();
		},
		tssPrev() {
			this.$refs.trendingSidebarSlide.prev();
		},
		tssNext() {
			this.$refs.trendingSidebarSlide.next();
		},
	},
};
</script>

<style></style>
