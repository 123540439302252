<template>
	<router-link :to="`/news/${slug}/${news.id}`" class="gallery_item">
		<template>
			<div :class="`gallery_item_thumb ${default_language.direction}`">
				<img
					v-if="news.cover"
					:src="thumb_url"
					alt="gallery"
				/>
			</div>
			<div class="gallery_item_content">
				<div class="post-meta">
					<div class="meta-categories" v-if="category">
						<router-link :to="`category/${category.title[default_language.code]}`">{{ category.title[default_language.code] }}</router-link>
					</div>
					<div :class="`meta-date ${default_language.direction}`">
						<span>{{ news.created_at | dateFilter }}</span>
					</div>
				</div>
				<h4 :class="`title text-${default_language.alignment}`">
					<a href="javascript:void(0)">{{ news.title }}</a>
				</h4>
			</div>
		</template>
	</router-link>
</template>
  
<script>
	export default {
		props: {
			
			counting: {
				type: Boolean,
			},

			count: {
				type: Number,
			},
			news: {
				type: Object,
				required: true
			}
		},
		
		computed: {
			thumb_url() {
				let url = this.news.cover.original_url
				let re = /(?:\.([^.]+))?$/;
				let extension = re.exec(url)[1];

				let name = this.news.cover.name

				const name_index = url.indexOf(name)
				url = url.substring(0, name_index) + 'conversions/' + url.substring(name_index);

				const index = url.indexOf(extension)
				url = url.substring(0, index - 1) + '-thumb' + url.substring(index - 1);

				// let new_extension = extension.replace('e', '')

				return url.replace(extension, 'jpg')
			},

			default_language(){
				return this.$store.getters.default_language
			},

			category(){
				return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
			},

			slug(){
				return this.news.title.replace(/\s+/g, '-').toLowerCase();
			}
		},
		
		methods: {
			
		},

		mounted () {

		},
	};
  </script>
  
<style scoped>
	.gallery_item_thumb.rtl {
		margin-left: 15px;
		margin-right: 0;
	}
</style>
  