<template>
  <div class="trending-social">
		<div :class="['section-title', dark ? 'section-title-2' : '']">
		<h3 :class="`title text-${default_language.alignment}`">{{ $t('main.follow_us') }}</h3>
		</div>
		<ul v-for="(chunk, index) in social_media" :key="index">

			<li v-for="(media, i) in chunk" :key="`${i}_${index}`">
				<a target="_blank" :href="media.link" :style="`background: ${media.color}`">
					<i :class="media.icon"></i>
					<span>{{ media.count }}</span>
					<p>{{ media.title }}</p>
				</a>
			</li>
			
		</ul>
  </div>
</template>

<script>
export default {
	props: {
		dark: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		social_media(){
			let social_media = this.$store.getters.social_media
			const splitEvery = (n, xs, y=[]) => xs.length===0 ? y : splitEvery(n, xs.slice(n), y.concat([xs.slice(0, n)]))
			return splitEvery(3, social_media)
		},
		default_language(){
			return this.$store.getters.default_language
		},
	}
};
</script>

<style></style>
