import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { Storage } from '../helpers/storage';
let default_language = Storage.get('default_language')

if(!default_language){
	default_language = {
		id: 1,
		code: 'en',
		name: 'English',
		direction: 'ltr',
		alignment: 'left',
	}
}
window.default_language = default_language

Vue.use(Vuex);
export const store = new Vuex.Store({
    state: {
        videoPopup:false,
		video_link: '',
		languages: [],
		categories: [],
		tags: [],
		trends: [],
		advertisement: [],
		about: [],

		default_language: default_language,

		basic_info: {
			social_media: [
				{id: '', icon: 'fab fa-facebook-f', title: 'Facebook', link: 'https://www.facebook.com/ChashmNews.Net', color: '#3b5998', count: '+1000'},
				// {id: '', icon: 'fab fa-instagram', title: 'Instagram', link: '', color: '#9b45d5',  count: 1000},
				{id: '', icon: 'fab fa-twitter', title: 'Twitter', link: 'https://mobile.twitter.com/chashmNews_', color: '#1da1f2',  count: '+1000'},
				{id: '', icon: 'fab fa-youtube', title: 'YouTube', link: 'https://www.youtube.com/@ChashmNews', color: '#ff5050',  count: '+1000'},
				// {id: '', icon: 'fab fa-linkedin', title: 'LinkedIn', link: '', color: '#1ab7ea',  count: 1000},
			]
		}

    },

	getters:{
		default_language(state){
			return state.default_language
		},

		languages(state){
			return state.languages
		},

		about(state){
			return state.about
		},

		categories(state){
			return state.categories
		},

		tags(state){
			return state.tags
		},

		trends(state){
			return state.trends
		},

		advertisement(state){
			return state.advertisement
		},


		video_link(state){
			return state.video_link
		},

		social_media(state){
			return state.basic_info.social_media
		}
	},

    mutations: {
        SET_VALUE_VIDEO:(state, payload)=> {
            console.log(payload)
            state.videoPopup = payload
            
        },

		SET_DEFAULT_LANGUAGE(state, default_language){
			state.default_language = default_language
		},

		INIT(state, data){
			state.languages = data.languages
			state.about = data.about
			state.categories = data.categories
			state.tags = data.tags
			state.trends = data.trends
			state.advertisement = data.advertisement
		},

		VIDEO_LINK(state, link){
			state.video_link = link
		}
    },
    actions: {
        toggleVideo({state,commit }) {
            commit('SET_VALUE_VIDEO',!state.videoPopup)
        },

		setDefaultLanguage(context, language){
			return new Promise((resolve, reject) => {
				try {
					Storage.set('default_language', language)
					context.commit('SET_DEFAULT_LANGUAGE', language)
					resolve({})
				} catch (error) {
					reject(error)
				}
			})
		},

		async init({commit}){
			try{
				let { data } = await axios.get('init')
				commit('INIT', data)
				Promise.resolve({})
			} catch (error) {
				Promise.reject(error)
			}

		},


    }

})