<template>
  <div>
    <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
    <Header @toggleSidebar="toggleSidebar" class="header-dark" :darkClass="{ menuItem: 'newspark-dark-menu-items' }" />
    
	<slot></slot>
    

    <footer-dark />

    <!--====== GO TO TOP PART START ======-->
	<back-to-top />
  </div>
</template>

<script>
import Header from "../Utility/Header/index";
import BackToTop from "../Helpers/BackToTop.vue";
import FooterDark from "../Utility/Footer/FooterDark.vue";
import OurLatestNews from "../Utility/Common/OurLatestNews.vue";
import Posts from "../Data/About";
import Drawer from "../Mobile/Drawer.vue";
export default {
	components: { Header, OurLatestNews, Drawer, FooterDark, BackToTop },
	data: () => ({
		posts: Posts.data,
		sidebar: false,
	}),
	created() {
		// document.addEventListener("scroll", this.topToBottom);
	},
	methods: {
		toggleSidebar() {
			this.sidebar = !this.sidebar;
		},
		
	},

	mounted () {
		document.body.classList.add("home-dark");;
	},

	beforeDestroy() {
		document.body.classList.remove("home-dark");
	},
};
</script>

<style></style>
