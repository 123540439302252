 <template>
  <section class="all-post-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12">
			<template v-for="(category, index) in category_news">
				<entertainment-news
					:key="index"
					:category_news="category"
					:darkClass="{
						item: 'trending-news-item-dark',
						title: 'section-title-2',
					}"
				/>
			</template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EntertainmentNews from "../Home/EntertainmentNews.vue";


export default {
	props: {
		category_news: {
			type: Array,
			required: true,
		}
	},
	components: { 
		EntertainmentNews, 
	},
};
</script>

<style>
</style>