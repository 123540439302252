<template>
	<div class="post-entertainment my-4">
		<div
			:class="[
				'section-title',
				darkClass && 'title' in darkClass ? darkClass.title : '',
			]"
		>
			<h3 :class="`title text-${default_language.alignment}`">{{ category_news.title[default_language.code] }}</h3>
		</div>
		<div class="row">
			<template v-for="(news, index) in category_news.news">
				<div :key="index" class="col-lg-4 col-md-4">
					<trending-news-card
						:icon="false"
						:news="news"
						:class="[
							darkClass && 'item' in darkClass ? darkClass.item : '',
						]"
					></trending-news-card>
				</div>
			</template>
		</div>

		<br>
		<br>
		<br>
	</div>
</template>

<script>

import TrendingNewsCard from "../Utility/Cards/TrendingNewsCard.vue";
export default {
 	components: {TrendingNewsCard },

  	props: {
		darkClass: {
			type: Object,
		},

		category_news: {
			type: Object,
			required: true,
		}
  	},

	computed: {
		default_language() {
			return this.$store.getters.default_language
		}
	},
	data: () => ({

	}),
};
</script>

<style>
</style>