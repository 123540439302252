import Vue from 'vue'
import VueRouter from 'vue-router'
import Dark from '../views/Dark'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import fourOfour from '../components/404.vue'
import PostThree from '../components/Posts/PostThree/index'

// 
import CategoryNews from '../components/category/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dark
  },
  
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },

  {
    path: '/about-us',
    name: 'About',
    component: About
  },

  {
    path: '/404',
    name: '404',
    component: fourOfour
  },


  {
	path: '/category/:title',
	name: 'category_news',
	component: CategoryNews
  },

  {
	path: '/news/:title/:news_id',
	name: 'news',
	component: PostThree
  },

  {
	path: '/about/:title/:about_id',
	name: 'about',
	component: About
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
