<template>
  <div href="javascript:void(0)" class="d-block">
    <div class="post_gallery_play">
      <div class="bg-image">
        <img
          :src="datas.media[0].original_url"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
		<div class="post__gallery_play_content" style="z-index: 10">
			<div class="post-meta">
				<div class="meta-categories">
					<router-link :to="`/category/${category.title[default_language.code]}`" v-if="category">{{ category.title[default_language.code] }}</router-link>
				</div>
				<div :class="`meta-date ${default_language.direction}`">
					<span>{{ datas.news_date }}</span>
				</div>
			</div>
			<h2 :class="`title text-${default_language.alignment}`">
				
				<a :href="(datas.target) ? datas.target : 'javascript:void(0)'">{{ datas.heading }}</a>
			</h2>
			<p :class="`d-block text-${default_language.alignment}`">
				{{ datas.description }}
			</p>
		</div>
		<div class="post_play_btn" @click.prevent="show_video_pop" v-if="datas.youtube_link">
			<a class="video-popup" :href="datas.youtube_link">
				<i class="fas fa-play"></i>
			</a>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  	props: {
		datas: {
			type: Object,
			required: true
		}
	},

	computed: {
		default_language() {
			return this.$store.getters.default_language 
		},

		category(){
			return this.$store.getters.categories.find(cat => cat.id == this.datas.category_id)
		}
	},

	methods: {
		show_video_pop() {
			this.$store.commit('VIDEO_LINK', this.datas.youtube_link)
			this.$store.dispatch('toggleVideo')
		}
	},
};
</script>

<style>
	.meta-date.rtl {
		padding-left: 0 !important;
		padding-right: 32px;
	}

	.meta-date.rtl::before {
		left: initial;
		right: 15px;
	}
</style>
