<template>
  <header class="header-area">
    <top-bar />
    <div class="header-centerbar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link to="/">
                <img :src="require(`@/assets/images/logo.png`)" alt="feature" :class="`float-${default_language.alignment}`" />
              </router-link>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="header-centerbar-ad" v-if="advertisement">
				<a :href="advertisement.target" :title="advertisement.title">
					<img :src="advertisement.media[0].original_url" alt=""/>
				</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-menubar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-sm-3 col-3">
            <svg
              class="menu-drawer"
              style="cursor: pointer"
              @click.prevent="hideSidebar"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              fill="currentColor"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"></path>
            </svg>
            <nav-items :darkClass="darkClass" />
          </div>
          <div class="col-lg-4 col-sm-9 col-9">
            <div class="header-menu-rightbar">
              <div class="header-menu-search">
                <ul>
					<li>
						<!-- <a href="#"><i class="fal fa-search"></i></a> -->
					</li>
                </ul>
              </div>
              <div :class="`nice-select-item ${default_language.direction}`">
                <select class="select-lang" @change="changeLanguage" v-model="language_model">
					<option 
						v-for="(language, index) in languages"
						:key="index" 
						:value="language.id" 
						:style="`text-align: ${language.alignment}`"
					>
						{{ language.name }}
					</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TopBar from "./TopBar.vue";
import NavItems from "../Common/NavItems.vue";
export default {
	components: { TopBar, NavItems },
	props: {
		image: {
			type: String,
			default: "logo.png",
		},
			darkClass: {
			type: Object,
		},
	},
	data: () => ({
		language_model: window.default_language.id,
	}),

	computed: {
		default_language(){
			return this.$store.getters.default_language
		},

		languages() {
			return this.$store.getters.languages 
		},

		advertisement(){
			return this.$store.getters.advertisement
		}
	},
	methods: {
		hideSidebar(e) {
			this.$emit("toggleSidebar", e);
		},

		async changeLanguage(){
			const language = this.languages.find(lang => lang.id == this.language_model)
			await this.$store.dispatch('setDefaultLanguage', language)
			window.location.reload()
		}
	},


};
</script>
<style scoped>
.select-lang {
  border: 1px solid #bababa;
  width: 100px;
  padding: 2px;
  color: #17191d;
  outline: none;
  border-radius: 7px;
}

.nice-select-item.rtl {
	margin-left: 10px !important;
}
</style>
