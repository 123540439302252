<template>
  <section class="feature-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div :class="['section-title', dark ? 'section-title-2' : '']">
            <h3 :class="`title text-${default_language.alignment}`">{{ $t('main.featured_news') }}</h3>
          </div>
        </div>
      </div>
      <div class="feature-post-slider position-relative">
        <span
			@click="featureSliderPrev"
			:class="`prev slick-arrow ${default_language.direction}`"
			style="display: block"
			><i class="fal fa-angle-left"></i>
		</span>
        <slider :settings="featureSliderSettings" ref="featureSlider">
          <template v-for="(news, index) in featured_news">
            <div class="px-2" :key="index">
              <overlay-card stype="small" :news="news" />
            </div>
          </template>
        </slider>
        <span
			@click="featureSliderNext"
			:class="`next slick-arrow ${default_language.direction}`"
			style="display: block"
			><i class="fal fa-angle-right"></i
			></span>
      </div>
    </div>
  </section>
</template>

<script>
import post from "../Data/post";
import Slider from "../Helpers/Slider.vue";
import OverlayCard from "../Utility/Cards/OverlayCard.vue";
export default {
	components: { Slider, OverlayCard },
	props: {
		dark: {
			type: Boolean,
		},
		featured_news: {
			type: Array,
			required: true
		}
	},
	computed: {
		default_language(){
			return this.$store.getters.default_language
		},
	},
	data: () => ({
		//feature
		post: post.data,
		featureSliderSettings: {
			slidesToShow: 4,
			slideToScroll: 1,
			arrows: false,
			autoplay: true,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slideToScroll: 1,
					},
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slideToScroll: 1,
					},
				},
			],
		},
	}),
	methods: {
		//feature
		featureSliderNext() {
			this.$refs.featureSlider.next();
		},
		featureSliderPrev() {
			this.$refs.featureSlider.prev();
		},
	},
};
</script>

<style scoped>
	
</style>
