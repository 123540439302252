
class storage {
	
	constructor(){
		this.key = '$2y$10$7gONqNIxoJkVaK0Crk.tBuP3pqjeMpnqMeXRMeJcG79Cpeur8twhy';
	}

	get(name){
		
		let item = localStorage.getItem(`${this.key}${name}`)
		
		if(item){
			
			return JSON.parse(item)
		}else{
			return null			
		}
	}

	set(name, item){
				
		localStorage.setItem(`${this.key}${name}`, JSON.stringify(item))
	}

	remove(name){
		localStorage.removeItem(`${this.key}${name}`)
	}

	test(){
		
	}
}

const Storage = new storage()

export { Storage }