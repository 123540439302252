<template>
	<div>
	  <category-layout>
		<section class="about-item-area">
		  <div class="container">
			<div class="row">
			  <div class="col-lg-12">
				<div class="about-author-content pt-15">
				  <nav aria-label="breadcrumb">
					<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<router-link to="/" class="text-white">{{ $t('main.home') }}</router-link>
						</li>
						
						<li class="breadcrumb-item active" aria-current="page">
							{{ category.title[default_language.code] }}
						</li>
					</ol>
				  </nav>
				</div>
			  </div>
				<div class="col-lg-12">
					<div class="about-tab-btn mt-40">
						<div class="archive-btn">
							<div class="section-title section-title-2">
								<h3 :class="`title text-${default_language.alignment}`">{{ category.title[default_language.code] }}</h3>
							</div>
						</div>
						<div class="about-post-items">
							<div class="row">
								<div class="col-lg-12">
									<template v-for="(post, index) in news.data">
										<category-news-card :news="post" :key="index"></category-news-card>
									</template>
								</div>
								<div class="col-lg-12">

										<pagination :data="news" @pagination-change-page="change_page" :limit="2"></pagination>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		  </div>
		</section>
	  </category-layout>
	</div>
  </template>
  
  <script>
  import CategoryLayout from "./CategoryLayout.vue";
  import Posts from "../Data/TrendingHomeThree";
  import StyleOne from "../Utility/Sidebar/StyleOne.vue";
  import CategoryNewsCard from "../Utility/Cards/CategoryNewsCard.vue";
  export default {
		name: 'CategoryNews',

		components: { CategoryLayout, StyleOne, CategoryNewsCard },
		computed: {
			page(){
				return this.$route.query.page
			},

			category_title() {
				return this.$route.params.title 
			},

			default_language(){
				return this.$store.getters.default_language
			},

			languages(){
				return this.$store.getters.languages
			},	

			__category(){
				return this.$store.getters.categories.find( cat => cat.title[this.default_language.code] == this.category_title)
			},

			category(){
				// return this.$store.getters.categories.find( cat => cat.title[this.default_language.code] == this.category_title)
				let category = null
				this.$store.getters.categories.map( cat => {
					this.languages.map(lang => {
						console.log('lang', cat.title[lang.code]);
						if(cat.title[lang.code] == this.category_title){
							category = cat
						}
					})
				})

				return category
			},

			params(){
				let page = this.page
				let category_id = this.category.id

				return {
					page: page,
					category_id: category_id
				}
			}
		},

		data: () => ({
			posts: Posts.data,
			news: {
				data: []
			}
		}),
		
		methods: {

			change_page(page = 1){
				if(page != this.page){
					this.$router.push({
						name: this.$route.name,
						params: this.$route.params,
						query: Object.assign({}, this.$route.query, {page: page})
					})
				}
			},

			get_news() {
				this.$Progress.start()
				// this.news.data = []

				axios.get(`category/${this.category.id}/news`, {params: this.params})
				.then(({data}) => {
					this.news = data
					this.$Progress.finish()
				}).catch((err) => {
					this.$Progress.fail()
				});
			}
		},

		mounted () {
			this.get_news();
		},

		watch: {
			params: {
				handler(val, old){
					this.get_news()
				},

				deep: true
			}
		}
  };
  </script>
  

  