<template>
	<div :class="[ 'post_gallery_sidebar', darkClass && 'section' in darkClass ? darkClass.section : '',]">
	  
		<div class="tab-content">
			<div
				class="tab-pane fade show active"
				role="tabpanel"
				aria-labelledby="pills-home-tab"
			>
				<div class="post_gallery_items" v-if="news && news.length > 0">
					<template v-for="(latest, index) in news">
						
					<latest-news-card
						:key="index"
						:class="[darkClass && 'item' in darkClass ? darkClass.item : '']"
						:news="latest"
					/>
					</template>
				</div>
			</div>
		</div>

	</div>
  </template>
  
  <script>

  import RowCard from "../Cards/RowCard.vue";
  import LatestNewsCard from "../Cards/LatestNewsCard.vue";

  export default {
	components: { RowCard, LatestNewsCard },
	props: {

	  darkClass: {
		type: Object,
		default: () => {},
	  },
	  signup: {
		type: Boolean,
		default: true,
	  },
	  trendingBigPost: {
		type: Boolean,
		default: true,
	  },
	  trendingShortPost: {
		type: Boolean,
		default: true,
	  },
	  ad: {
		type: Boolean,
		default: true,
	  },
	  sharePost: {
		type: Boolean,
		default: true,
	  },

	  news: {
		type: Array,
		required: true
	  }
	},
	data: () => ({
	  selectedGallery: "trendy",
	  trendingNews: {
		autoplay: true,
		slideToScroll: 1,
		slidesToShow: 1,
		arrows: false,
		dots: false,
	  },
	}),
	methods: {
	  selectGalleryTab(value) {
		this.selectedGallery = value;
	  },
	  trendingNewsPrev() {
		this.$refs.trendingNews.prev();
	  },
	  trendingNewsNext() {
		this.$refs.trendingNews.next();
	  },
	},
  };
  </script>
  
  <style></style>
  