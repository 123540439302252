<template>
	<div class="trending-news-item">
		<div class="trending-news-thumb">
			<router-link :to="`/news/${slug}/${news.id}`">
				<img :src="news.cover.preview_url" alt="feature" />
				<div class="icon" v-if="icon">
					<a href="javascript:void(0)">
						<i class="fas fa-bolt"></i>
					</a>
				</div>
			</router-link>
		</div>

	  	<div class="trending-news-content">
			<div class="post-meta">
				<div class="meta-categories">
					<router-link :to="`/category/${category.title[default_language.code]}`">{{ category.title[default_language.code] }}</router-link>
				</div>
				<div :class="`meta-date ${default_language.direction}`">
					<span>{{ news.created_at | dateFilter }}</span>
				</div>
			</div>
			<h3 :class="`title text-${default_language.alignment}`">
				<router-link :to="`/news/${slug}/${news.id}`">{{ news.title }}</router-link>
			</h3>
			<p :class="`text-${default_language.alignment}`">
				{{ news.excerpt }}
			</p>
			<router-link :to="`/news/${slug}/${news.id}`" :class="`float-${default_language.alignment}`">{{ $t('main.read_more') }}</router-link>
	  	</div>
	</div>
  </template>
  
  <script>
  export default {
	props: {
		news: {
			type: Object,
		},
		
		stype: {
			type: String,
		},

		icon: {
			type: Boolean,
			default: true
		}
	},

	computed: {
		default_language(){
			return this.$store.getters.default_language
		},
		
		category() {
			return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
		},

		slug(){
			return this.news.title.replace(/\s+/g, '-').toLowerCase();
		}
	},
  };
  </script>
  
  <style></style>
  