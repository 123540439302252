<template>
	<div>
	  <drawer @toggleSidebar="toggleSidebar" :sidebar="sidebar" />
  
	  <Header @toggleSidebar="toggleSidebar" class="header-dark" :darkClass="{ menuItem: 'newspark-dark-menu-items' }"  />
	  <!--====== POST LAYOUT 1 PART START ======-->
	  <section class="post-layout-1-area post-layout-3-area pb-80 post__gallery__area">
		  <div class="container">
			  <div class="row">
				  <div class="col-lg-12">
					  <div class="about-author-content">
						  <nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
								  <li class="breadcrumb-item"><router-link :to="{path: '/'}" class="text-white">{{ $t('main.home') }}</router-link></li>
								 
								  <li class="breadcrumb-item active" aria-current="page" v-if="about.title">
									  {{ about.title[default_language.code] }}
								  </li>
							  </ol>
						  </nav>
					  </div>
				  </div>
				  <div class="col-lg-8">
					  <div class="post-layout-top-content post-layout-top-content-3">
						  
						  <div class="post-content">
							  <h3 :class="`title text-${default_language.alignment}`">
								  {{ about.title[default_language.code] }}
							  </h3>
							  <div class="post-author">
								  <div class="author-info pl-0">
									  <ul>
										  <li>{{ about.created_at | dateFilter }}</li>
									  </ul>
								  </div>
							  </div>
							  <div class="thumb" v-if="about.media">
								  <img :src="about.media[0].original_url" alt="" />
							  </div>
						  </div>
  
						  <div class="post-text mt-30">
							  <div v-html="about.description[default_language.code]"></div>
						  </div>
					  
					  
					  
					  <div class="post-tags">
						  <!-- <ul>
						  <li>
							  <a href="#"><i class="fas fa-tag"></i> Tags</a>
						  </li>
						  <li><a href="#">Health</a></li>
						  <li><a href="#">World</a></li>
						  <li><a href="#">Corona</a></li>
						  </ul> -->
					  </div>
					  </div>
					  
				  </div>
				  <div class="col-lg-4">
  
					  <slide-side-bar
						  :trendingShortPost="false"
						  :signup="false"
						  :trendingBigPost="false"
						  :ad="false"
						  :sharePost="false"
						  :darkClass="{
							  section: 'post_gallery_sidebar_dark',
							  item: 'gallery_item_dark',
						  }"
						  role="sidebar"
						  :news="latest_news"
					  />
  
				  </div>
			  </div>
		  </div>
	  </section>
  
  
  
  
  
  
	  <!--====== ADD PART ENDS ======-->
	  <footer-dark></footer-dark>
  
	  <!--====== GO TO TOP PART START ======-->
	  <back-to-top></back-to-top>
	  <!--====== GO TO TOP PART ENDS ======-->
	</div>
  </template>
  
  <script>
  import Header from "../Utility/Header/index";
  import StyleOne from "../Utility/Sidebar/StyleOne.vue";
  import SlideSideBar from "../Utility/Sidebar/SlideSideBar.vue";
  import Posts from "../Data/NewsRowCard";
  import OurLatestNews from "../Utility/Common/OurLatestNews.vue";
  import FooterDark from "../Utility/Footer/FooterDark.vue";
  import BackToTop from "../Helpers/BackToTop.vue";
  import Drawer from "../Mobile/Drawer.vue";
  
  export default {
	  components: { Header, StyleOne, SlideSideBar, OurLatestNews, Drawer, FooterDark, BackToTop },
	  
	  computed: {
		  about_id() {
			  // return 1
			  return this.$route.params.about_id 
		  },
  
		  default_language(){
			  return this.$store.getters.default_language
		  },
  
		  category(){
			  return this.$store.getters.categories.find(cat => cat.id == this.news.category_id)
		  }
	  },
  
	  data: () => ({
		  posts: Posts.data,
		  sidebar: false,
		  about: {
			title: {},
			description: {}
		  },
		  latest_news: []
	  }),
  
	  created() {
		  document.addEventListener("scroll", this.topToBottom);
	  },
  
	  methods: {
		  toggleSidebar() {
			  this.sidebar = !this.sidebar;
		  },
		  topToBottom() {
			  const result = document.querySelector(".go-top");
			  if (
				  document.body.scrollTop > window.innerHeight ||
				  document.documentElement.scrollTop > window.innerHeight
			  ) {
				  result.classList.add("active");
			  } else {
				  result.classList.remove("active");
			  }
		  },
  
		  get_news(){
			  this.$Progress.start()
			  axios.get(`about/${this.about_id}`)
			  .then(({data}) => {
				  this.about = data.about
				  this.latest_news = data.latest_news
				  this.$Progress.finish()
			  }).catch((err) => {
				  this.$Progress.fail()
				  this.$Progress.finish()
			  });
		  }
	  },
  
	  mounted () {
		  document.body.classList.add("home-dark");
		  this.get_news()
	  },
  
	  beforeDestroy() {
		  document.body.classList.remove("home-dark");
	  },
  
	  watch: {
		  about_id: {
			  handler(val, old){
				  this.get_news()
			  }
		  }
	  }
  };
  </script>
  
  <style></style>
  