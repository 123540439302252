<template>
  <div>
    
    <div class="footer-copyright footer-copyright-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
				class="
					copyright-item
					d-block d-md-flex
					justify-content-between
					align-items-center
            	"
            >
				<p>&copy; {{ date.getFullYear() }}, {{ $t('main.copyright') }}</p>
				<ul>
					<!-- <li><a href="#">About</a></li> -->
					<li><a href="#">{{ $t('main.privacy_policy') }}</a></li>
					<!-- <li><a href="#">Contact Us</a></li> -->
              	</ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	computed: {
		social_media() {
			return this.$store.getters.social_media 
		},

		date(){
			return new Date()
		}
	},
};
</script>

<style>
</style>